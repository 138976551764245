import moment from "moment";

export function stringToDatetime(dateString) {
    return `${dateString.split("/").reverse().join("-")} 00:00:00.0000000`;
}

export function stringToDatetimeZone(dateString) {
    return `${dateString.split("/").reverse().join("-")} 00:00:00.000z`;
}

export function filterData(valor) {
    return valor ? valor
      .split("T")[0]
      .split("-")
      .reverse()
      .join("/") : '';
  }

  export function filterDataHora(valor) {
    var date = new Date(valor);
    return valor ? moment(date).format("DD/MM/YYYY HH:mm") : '';
}

export function nowDateString() {
    var date = new Date();
    return moment(date).format("YYYY-MM-DD_HH:mm:ss");
}