import client from "@/api";
import store from "@/store";
import { limpaCPF } from "@/utils/masks";
import {
  _rotaListarPremiacaoInstantanea,
  _rotaHistoricoPremiacaoInstantanea,
  _rotaAtualizarStatusPremiacaoInstantanea,
  _rotaEnviarPlanilhaPremiacao,
  _rotaGerarRelatorioGanhadores,
  _rotaVerificaPlanilha,
} from "@/api/_caminhosApi";
//
export function listarPremiacaoInstantanea(page, qtd, filtros) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("premiacao/SET_TABELA", "");

  Object.keys(filtros).map((item) => {
    if (filtros[item] == "") {
      filtros[item] = 0;
    }
  });

  if (filtros.cpf) {
    filtros.cpf =
      filtros.cpf !== undefined ? limpaCPF(filtros.cpf) : filtros.cpf;
  }
  if (filtros.statusPremiacao) {
    filtros.statusPremiacao = filtros.statusPremiacao.id;
  }

  client
    .post(
      `${_rotaListarPremiacaoInstantanea()}?pagina=${page}&qtdePorPagina=${qtd}`,
      filtros
    )
    .then((resp) => {
      store.commit("premiacao/SET_TABELA", resp.data.lista);
      store.commit("premiacao/SET_DATA_PAGINACAO", resp.data.paginacao);
    })
    .catch((erro) => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function enviarPlanilhaPremiacao(file) {
  store.commit("interacoes/SET_LOADING", true);

  client
    .post(`${_rotaEnviarPlanilhaPremiacao()}`, file)
    .then(() => {
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: "Tabela enviada com sucesso!",
        tipo: "sucesso",
      });
    })
    .catch((erro) => {
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: erro.response.data.message,
        tipo: "erro",
      });
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function gerarRelatorio() {
  return new Promise((resolve) => {
    store.commit("interacoes/SET_LOADING_RELATORIO", true);
    client
      .get(_rotaGerarRelatorioGanhadores())
      .then((resp) => {
        resolve(resp);
      })
      .catch((erro) => {
        console.log(erro);
      })
      .finally(() => {
        store.commit("interacoes/SET_LOADING_RELATORIO", false);
      });
  });
}

export function verificaPlanilha() {
  store.commit("interacoes/SET_LOADING_RELATORIO", true);
  client
    .get(_rotaVerificaPlanilha())
    .then((resp) => {
      store.commit("premiacao/SET_VERIFICA_PLANILHA", resp.data);
    })
    .catch((erro) => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING_RELATORIO", false);
    });
}

export function historicoPremiacaoInstantanea(usuarioId, idHistoricoPremiacaoIsntantanea
  , dataCadastroPremiacao) {

  store.commit("interacoes/SET_LOADING", true);
  client
    .get(`${_rotaHistoricoPremiacaoInstantanea()}?usuarioId=${usuarioId}&idHistoricoPremiacaoIsntantanea=${idHistoricoPremiacaoIsntantanea}&dataCadastroPremiacao=${dataCadastroPremiacao}`)
    .then((resp) => {
      console.log(resp)
      store.commit("interacoes/SET_MODAL_MODERAR", {
        ativado: true,
        mensagem: resp.data.mensagem,
        cupons: resp.data.cupons,
        sucesso: resp.data.sucesso,
        usuarioId: usuarioId,
        idHistoricoPremiacaoIsntantanea: idHistoricoPremiacaoIsntantanea
      });
    })
    .catch((erro) => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
export function atualizarStatusPremiacaoInstantanea(usuarioId, idHistoricoPremiacaoIsntantanea
  , idStatusPremiacao) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(`${_rotaAtualizarStatusPremiacaoInstantanea()}?usuarioId=${usuarioId}&idHistoricoPremiacaoIsntantanea=${idHistoricoPremiacaoIsntantanea}&idStatusPremiacao=${idStatusPremiacao}`)
    .then((resp) => {
      store.commit("interacoes/SET_MODAL_MODERAR", {
        ativado: false,
        mensagem: "",
        tipo: ""
      });
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: resp.data.mensagem,
        tipo: "sucesso",
      });
      return listarPremiacaoInstantanea(1, 10, {});
    })
    .catch((erro) => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}